@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-in-right {
  animation: slideInRight 1s ease-out;
}

/* Custom Swiper Pagination Styles */
.swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  background-color: #fff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #4CAF50;
  /* Set the active dot color */
  transform: scale(1.2);
  /* Optionally make the active dot a bit bigger */
}